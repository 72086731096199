import React, { useEffect, useReducer } from 'react';
import './style.scss';
import ContactForm from '../../components/contact';
import ScrollToMe from '@mborecki/react-scroll-to-me';

const IMAGES = [
    '/images/img.jpg',
    '/images/P1016548.jpg'
]

export default function HomePage() {

    useEffect(() => {
        window.document.body.style.backgroundImage = `url(${IMAGES[Math.floor(Math.random() * IMAGES.length)]})`;
    }, [])

    const [contactTrigger, moveToContact] = useReducer((state) => {
        return state+1;
    }, 0)


    return (
        <>
            <main>
                <header>
                    <h1>Marek Borecki</h1>
                    <h2>Programista Frontend</h2>
                </header>
                {/* <p>Szewc w dziurawych butach chodzi...</p> */}
                <nav>
                    <a href="https://github.com/mborecki" className="icon-github" aria-label="Profil github.com"> </a>
                    <a href="https://www.linkedin.com/in/marek-borecki/" className="icon-linkedin" aria-label="Profil linkedin.com"> </a>
                    <a className="icon-mail" onClick={moveToContact}> </a>
                </nav>
            </main>
            <section className="contact">
                <ScrollToMe trigger={contactTrigger} behavior="smooth"></ScrollToMe>
                <ContactForm></ContactForm>
            </section>
        </>
    )
}
