import Axios from "axios";
import { useCallback } from "react";

export default function useSendMail() {
    return useCallback((sender: string, email: string, subject: string, msg: string, rcv2: string, rcv3: string) => {
        return Axios.post('//borek224.usermd.net/api/mail', {
            sender,
            email,
            subject,
            msg,
            rcv2,
            rcv3
        });
    }, [])
}
