import React, { useRef, useState } from "react";
import { useCallback } from "react";
import useSendMail from "./use-send-mail"
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useEffect } from "react";

type Props = {}

const Container = styled.div`

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 50px 0;

    form {
        width: 100%;
    }

    .wrapper {
        max-width: 800px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        * {
            outline-color: #B4A173;
        }

        input, textarea {
            width: 80%;
            margin: .5em;
            background: rgba(0,0,0,.8);
            color: white;
            border: none;
            font-size: 1em;
            font-family: Lato;
        }


        textarea {
            height: 400px;
            line-height: 1.4em;
            padding: .5em;
            resize: none;
        }

        input {
            height: 2em;
            line-height: 2em;
            padding: 0 .5em;
        }

        input[type=submit] {
            width: 50%;
            cursor: pointer;
            font-weight: 300;

            &:focus,
            &:hover {
                background: #B4A173;
                color: black;
                font-weight: bold;
            }
        }

        .error {
            color: #c53c3c;
        }
    }
`

const ContactForm: React.FunctionComponent<Props> = () => {
    const { register, handleSubmit, watch, errors } = useForm();
    const sendMail = useSendMail();
    const form = useRef<HTMLFormElement>(null);
    const [sendError, setSendError] = useState<number>(0);
    const [sended, setSendded] = useState<boolean>(false);

    const onSubmit = useCallback(async (data) => {
        //@ts-ignore
        const grecaptcha = window.grecaptcha as any;

        let rcv2 = '';
        let rcv3 = '';

        if (form.current) {

            const rc2 = Array.from(form.current.elements).find(({id}) => id === 'g-recaptcha-response');

            if (rc2) {
                rcv2 = (rc2 as HTMLTextAreaElement).value;
            }
        }

        if (grecaptcha) {
            const rcToken = await grecaptcha.execute('6LcquLkZAAAAAEQCzLYSbH3BPWXS1DY3XcEMMCr-', { action: 'login' });
            rcv3 = rcToken;
        }

        const { sender, email, msg } = data;

        setSendError(0);
        sendMail(sender, email, 'Wiadomość z formularza', msg, rcv2, rcv3)
            .then(() => {
                setSendded(true)
            })
            .catch(({ response }) => {
                setSendError(response.data.error)
            });
    }, [sendMail, setSendded, setSendError]);

    const reCaptchaV2 = useRef<any>();
    const showReCaptchaV2 = (sendError as any) === 10002;

    useEffect(() => {

        if (showReCaptchaV2) {
            //@ts-ignore
            const grecaptcha = window.grecaptcha as any;

            if (grecaptcha) {
                grecaptcha.render(reCaptchaV2.current, {
                    sitekey: '6LeE-cwZAAAAAEra0-h-tkpXmOxcC14mPP4-N2Bd'
                });
            }
        }

    }, [showReCaptchaV2])

    return (
        <Container>
            <form onSubmit={handleSubmit(onSubmit)} ref={form}>
                <div className="wrapper">
                    <h2>Kontakt {sendError}</h2>
                    <input placeholder="Nadawca" name="sender" type="text" ref={register({ required: true })} />
                    {errors.sender && <p className="error">Proszę o wypełnienie tego pola.</p>}
                    <input placeholder="Email kontaktowy" name="email" type="email" ref={register({ required: true })} />
                    {errors.email && <p className="error">Proszę o wypełnienie tego pola.</p>}
                    <textarea placeholder="Wiadomość..." name="msg" ref={register({ required: true })}></textarea>
                    {errors.msg && <p className="error">Proszę o wypełnienie tego pola.</p>}
                    {showReCaptchaV2 && <>
                        RECAPTCHA!
                    <div
                            ref={reCaptchaV2}
                        >

                        </div>
                    </>
                    }
                    <input type="submit" value="Wyślij" />
                    {
                        sended && <p>Wiadomość wysłana!</p>
                    }
                </div>
            </form>
        </Container>
    )
}

export default ContactForm;
