import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ModulesProviders from './modules/providers';

import { Theme } from './themes';

ReactDOM.render(
    <React.StrictMode>
        <Theme>
            <ModulesProviders>
                <App />
            </ModulesProviders>
        </Theme>
    </React.StrictMode>,
    document.getElementById('root')
);
