import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import HomePage from './routes/Home';

import './icons.scss';

export default function App() {
    return (
        <BrowserRouter>
                <Switch>
                    <Route>
                        <HomePage />
                    </Route>
                </Switch>
        </BrowserRouter>
    )
}
